body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 数据看板 */
.DataBoard .Echarts1box{margin:0 15px;}
.DataBoard .Echarts2box{margin:15px;margin-top:-40px;}
.DataBoard .Titlebox{display:flex;margin:21.5px 15px 10px 15px;justify-content:space-between;}
.DataBoard .Titlebox .p1{width:8px;height:8px;background-color:#00C6FF;border-radius:50px;margin-top: 8px;}
.DataBoard .Titlebox .p2{width:8px;height:8px;background-color:#FE2B54;border-radius:50px;margin-top: 8px;}
.DataBoard .Titlebox .title1{font-size:16px;font-weight:bold;color:#2c2c2c;margin-left:4.5px;}
.DataBoard .Titlebox .title2{width:70px;height:18px;background-color:#F6F6F6;border-radius:9px;font-size:12px;line-height:18px;text-align:center;margin:5px 0;color:#999;}
.DataBoard .topbox{display:flex;justify-content:space-around;margin:0;z-index:9999;}
.DataBoard .topbox .blue{margin:0;width:60px;height:24px;border:0.5px solid #999;border-radius: 12px 11.79px 11.79px 12px ;color:#999;font-size:14px;text-align:center;line-height:24px;}
.DataBoard .topbox .blues{margin:0;width:60px;height:24px;background-color:#00C6FF;border-radius: 12px 11.79px 11.79px 12px ;color:#fff;font-size:14px;text-align:center;line-height:24px;}
.DataBoard .space{border-bottom:5px solid #F8F8F8;}

/* 协议 */
.Content{padding:35px 15.5px 30px 15.5px;}

/* 下载页 */
.Index{position: relative;}
.Index .bgimg{width:100%;height:439.5px;position: relative;}
.Index .logsbox{width:140px;margin:0 auto;position: absolute;
  top:35%;
  left:50%;
  transform: translate(-50%,-50%);}
.Index .log1{width:100px;height:100px;display:block;margin:0 auto;}
.Index .log2{width:100px;height:40px;display:block;margin:0 auto;}
.Index .log3{width:140px;height:14.5px;display:block;margin:0 auto;white-space:nowrap;text-align:center;}
.Index .maxbox{margin:0 auto;}
.Index .box{margin:0 auto;display:flex;width:220px;height:50px;background-color:#00C6FF;border-radius:50px;}
.Index .box .pic{width:27px;height:27px;display:block;margin:11.5px 10px 11.5px 43.5px;}
.Index .box a{font-size:17px;line-height:50px;font-weight:bold;color:#fff;text-decoration:none}



/* 主页分享 */
.indexShare{background-color:#333;position: fixed;width: 100%;height: 100%;top: 0px;padding: 0;border: 0;}
.indexShare .maxbox{margin:15px;position: relative;}
.indexShare .spot1{position: absolute;width:18px;height:18px;display:block;content:'';background-color:#333;border-radius:50px;z-index:9999;top: 65%;right:1%;}
.indexShare .spot2{position: absolute;width:18px;height:18px;display:block;content:'';background-color:#333;border-radius:50px;z-index:9999;top: 65%;left:1%;}
.indexShare .topbox{margin:0 auto;justify-content:center;}
.indexShare .topimg{width:100%;height:193px;display:block;margin:auto;border-radius:10px 10px 0 0;}
.indexShare .Boxtop{position: absolute;width:100%;height:515px;background-color:#fff;top:80%;border-radius:0 0 10px 10px;}
.indexShare .titleBox{display:flex;padding:16px;}
.indexShare .UserLog{position: absolute;top:-45px;right:14px;}
.indexShare .UserImg{width:91px;height:91px;display:block;border-radius:50%;border:4px solid #fff;}
.indexShare .title{font-size:21px;font-weight:bold;line-height:22.08px;margin:0;margin-right:5.58px;}
.indexShare .titleBox .Icon{width:22.08px;height:22.08px;display:block;}
.indexShare .dataBox{display:flex;padding:0 16px;border-bottom:1px solid #EFEFEF;padding-bottom:21px;}
.indexShare .dataBox .p1{font-size:11px;color:#999;margin-right:21.5px;}
.indexShare .dataBox .p1 span{font-size:17px;color:#333;font-weight:bold;margin-left:3.5px;}
.indexShare .bqBox{display:flex;padding:14px 16px;flex-wrap: wrap;}
.indexShare .bqBox .bqmin{height:25px;border-radius:8px;background-color:#F8F8F8;margin-right:6px;padding:5px;}
.indexShare .bqBox .bqmin p{font-size:11px;color:#333;line-height:17px;text-align:center;}
.indexShare .jianjieBox{height:34px;padding:0 16px;}
.indexShare .jianjieBox p{text-indent: 1em;font-size:13px;color:#666;line-height:18px;text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;}
.indexShare .lunboBox{height:120px;margin:20px 16px 30px 16px;border-radius:6px;}
.indexShare .lunboBox .lbImg{width:100%;height:120px;border-radius:6px;}
.indexShare .ant-carousel .slick-dots li.slick-active button{background-color:#FE1389;}
.indexShare .ant-carousel .slick-dots-bottom{bottom:0;left:0;margin-left:60%;margin-right:0;}
.indexShare .infoBox{display:flex;padding:16px;padding-top:20px;}
.indexShare .infoBox .avatar{width:45px;height:45px;display:flex;border-radius:50%;}
.indexShare .infoBox .t1{font-size:17px;color:#333;font-weight:bold;line-height:18px;margin:0;}
.indexShare .infoBox .t2{font-size:14px;color:#999;line-height:18px;margin-top:9.5px;}
.indexShare .infoBox .bnt{margin-left:auto;width:85px;height:34px;border-radius:6px;background-color:#00C6FF;font-size:14px;line-height:34px;font-weight: bold;text-align:center;color:#fff;}
.indexShare .bottext{margin:0 auto;font-size:14px;color:#333;line-height:18px;text-align:center;margin-top:15px;}
/* 视频分享 */
.VideoShare{background-color:#333;position: fixed;width: 100%;height: 100%;top: 0px;padding: 0;border: 0;}
.VideoShare .maxbox{margin:20px;}
.VideoShare .spot1{position: absolute;width:18px;height:18px;display:block;content:'';background-color:#333;border-radius:50px;z-index:9999;bottom:24%;right:1%;}
.VideoShare .spot2{position: absolute;width:18px;height:18px;display:block;content:'';background-color:#333;border-radius:50px;z-index:9999;bottom:24%;left:1%;}
/* .VideoShare .icon{width:55px;height:55px;display:block;position: absolute;z-index:99;top:36%;left:50%;transform: translate(-50%,-50%);} */
.VideoShare .topbox{margin-bottom:0;justify-content:center;}
.VideoShare .topimg{width:100%;object-fit: cover;height:560px;display:block;margin:auto;border-radius:10px 10px 0 0;}
.VideoShare .botbox{width:100%;height:142px;background-color:#fff;margin:auto;margin-bottom:152px;border-radius:0 0 10px 10px;position: relative;}
.VideoShare .botbox .minbox{display:flex;padding:20px 15px;}
.VideoShare .botbox .logpic{width:45px;height:45px;display:block;border-radius:50px;}
.VideoShare .botbox .name{font-size:17px;line-height:18px;font-weight:bold;color:#333;margin:0 8.5px;}
.VideoShare .botbox .userid{font-size:14px;line-height:18px;color:#999;margin:9.5px 9px;}
.VideoShare .botbox .codebox{width:85px;height:34px;margin-left:auto;border-radius:12px;background-color:#00C6FF;font-size:14px;color:#fff;font-weight:bold;text-align:center;line-height:34px;}
.VideoShare .botbox .bottext{font-size:14px;line-height:18px;color:#333;text-align:center;margin:0px;}
